import { useEffect, useState, useContext } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import classNames from 'classnames';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import Modal from 'react-modal';

import ModalContainer from './Components/Modals/ModalContainer';
import CreativeIntelligenceModalContent from './Components/Pages/Creative/CreativeIntelligenceModalContent/CreativeIngelligenceModalContent';
import ShutdownModal from './Components/Molecules/ShutdownModal/ShutdownModal';

import SiteNavigationContainer from './Components/Templates/SiteNavigationContainer/SiteNavigationContainer';

import MarketIndexPage from './Components/Pages/MarketIndex/MarketIndex';
import MarketIndexOverview from './Components/Pages/MarketIndexOverview/MarketIndexOverview';
import MarketIndexScorecard from './Components/Pages/MarketIndexScorecard/MarketIndexScorecard';
import MarketIndexSubfactors from './Components/Pages/MarketIndexSubfactors/MarketIndexSubfactors';
import MarketIndexCompare from './Components/Pages/MarketIndexCompare/MarketIndexCompare';

import ContentAnalysisPage from './Components/Pages/ContentAnalysis/ContentAnalysis';
import ContentAnalysisThemes from './Components/Pages/ContentAnalysisThemes/ContentAnalysisThemes';
import ContentAnalysisThemesLanding from './Components/Pages/ContentAnalysisThemesLanding/ContentAnalysisThemesLanding';
import ContentAnalysisThemesOverview from './Components/Pages/ContentAnalysisThemesOverview/ContentAnalysisThemesOverview';
import ContentAnalysisTopics from './Components/Pages/ContentAnalysisTopics/ContentAnalysisTopics';
import ContentAnalysisKeywords from './Components/Pages/ContentAnalysisKeywords/ContentAnalysisKeywords';
import BrandPersonality from './Components/Pages/BrandPersonality/BrandPersonality';

// TODO: standardize on shorter, more relevant file naming
import CreativePage from './Components/Pages/Creative/Creative';
import CreativeChannelsPage from './Components/Pages/CreativeChannels/CreativeChannels';
import CreativeChannelsOverview from './Components/Pages/CreativeChannelsOverview/CreativeChannelsOverview';
import CreativeChannelsAdvertising from './Components/Pages/CreativeChannelsAdvertising/CreativeChannelsAdvertising';
import CreativeChannelsScreenshots from './Components/Pages/CreativeChannelsScreenshots/CreativeChannelsScreenshots';
import CreativeChannelsScreenshotsCompare from './Components/Pages/CreativeChannelsScreenshotsCompare/CreativeChannelsScreenshotsCompare';
import CreativeChannelsScreenshotsMultiBrand from './Components/Pages/CreativeChannelsScreenshotsMultiBrand/CreativeChannelsScreenshotsMultiBrand';
import CreativeChannelsScreenshotsSingleBrand from './Components/Pages/CreativeChannelsScreenshotsSingleBrand/CreativeChannelsScreenshotsSingleBrand';

import DashboardOverview from './Components/Pages/CustomizableDashboards/DashboardOverview/DashboardOverview';
import DashboardsManagement from './Components/Pages/CustomizableDashboards/DashboardsManagement/DashboardsManagement';

import MetricsPage from './Components/Pages/Metrics/Metrics';

import RecommendationsPage from './Components/Pages/Recommendations/Recommendations';

import AdminSettingsPage from './Components/Pages/AdminSettings/AdminSettings';
import SettingsUsers from './Components/Organisms/SettingsUsers/SettingsUsers';
import SettingsGlobalMarkerEvents from './Components/Organisms/SettingsGlobalMarkerEvents/SettingsGlobalMarkerEvents';

import ProfilePage from './Components/Pages/Profile/Profile';
import ProfileOverview from './Components/Organisms/ProfileOverview/ProfileOverview';

import ProfileMarkerEvents from './Components/Organisms/ProfileMarkerEvents/ProfileMarkerEvents';

import BNContext from './contexts/BNContext';
import useRouter from './hooks/useRouter';

import {
  GoogleAnalyticsCode,
  GoogleTagManagerInit,
} from './utils/GoogleAnalalytics';
import { FeatureFlag } from './utils/featureFlags';

import styles from './App.module.scss';

// Bind Modal to App for accessibility: https://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement('#root');

function App() {
  const { FEATURE_FLAGS } = useContext(BNContext);
  const flags = useFlags();

  const [isOpenBNAccessModal, setIsOpenBNAccessModal] = useState(false);
  const handleCloseBNAccessModal = () => setIsOpenBNAccessModal(false);

  const [isShutdownModalOpen, setIsShutdownModalOpen] = useState(true);
  const closeShutdownModal = () => setIsShutdownModalOpen(false);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'prod') {
      ReactGA.initialize(GoogleAnalyticsCode);
      TagManager.initialize(GoogleTagManagerInit);
    }
  }, []);

  const location = useLocation();
  const { updateRoute } = useRouter();
  const navigate = useNavigate();

  const [previousPath, setPreviousPath] = useState(null);
  const homePage = flags[FeatureFlag.ViewDashboards]
    ? '/dashboards'
    : '/market-index/overview';

  /**
   * Handle redirects for brand navigator and creative access based on feature flags.
   */
  useEffect(() => {
    const brandNavigatorPages = [
      'market-index',
      'content-analysis',
      'metrics',
      'recommendations',
      'admin-settings',
    ];

    // if brand navigator access and no creative access, then redirect to market index if trying to access creative pages
    if (
      flags[FeatureFlag.BNAccess] &&
      !flags[FeatureFlag.CreativeAccess] &&
      location.pathname.includes('creative')
    ) {
      updateRoute({ pathname: '/market-index/overview', replace: true });
      setIsOpenBNAccessModal(true);
    }

    // if creative access and no brand navigator access, then redirect to creative if trying to access brand navigator pages
    if (
      flags[FeatureFlag.CreativeAccess] &&
      !flags[FeatureFlag.BNAccess] &&
      brandNavigatorPages.some((page) => location.pathname.includes(page))
    ) {
      updateRoute({ pathname: '/creative/channels/overview', replace: true });
      setIsOpenBNAccessModal(true);
    }
  }, [location, flags, updateRoute]);

  useEffect(() => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  }, [navigate, location.pathname]);

  useEffect(() => {
    if (previousPath !== location.pathname) {
      setPreviousPath(location.pathname);
      window.scrollTo(0, 0);
    }
  }, [location, previousPath, setPreviousPath]);

  return (
    <div className={classNames(styles.App)}>
      <ModalContainer
        isOpen={isOpenBNAccessModal}
        closeModal={handleCloseBNAccessModal}
      >
        <CreativeIntelligenceModalContent />
      </ModalContainer>

      <ShutdownModal
        isModalOpen={isShutdownModalOpen}
        closeModal={closeShutdownModal}
      />

      <Routes>
        <Route path="/" element={<SiteNavigationContainer />}>
          {flags[FeatureFlag.BNAccess] && (
            <>
              <Route index element={<Navigate to={homePage} replace />} />

              <Route path="market-index" element={<MarketIndexPage />}>
                <Route path="overview" element={<MarketIndexOverview />} />
                <Route path="scorecard" element={<MarketIndexScorecard />} />
                <Route path="subfactors" element={<MarketIndexSubfactors />} />
                <Route path="compare" element={<MarketIndexCompare />} />

                <Route
                  index
                  element={<Navigate to="/market-index/overview" replace />}
                />
                <Route
                  path="/market-index/factors"
                  element={<Navigate to="/market-index/subfactors" replace />}
                />
              </Route>

              <Route path="content-analysis" element={<ContentAnalysisPage />}>
                <Route path="themes" element={<ContentAnalysisThemes />}>
                  <Route index element={<ContentAnalysisThemesLanding />} />

                  <Route
                    path="overview"
                    element={<ContentAnalysisThemesOverview />}
                  />
                </Route>

                <Route path="topics" element={<ContentAnalysisTopics />} />
                <Route path="keywords" element={<ContentAnalysisKeywords />} />

                <Route
                  path="brand-personality"
                  element={<BrandPersonality />}
                />

                <Route
                  index
                  element={<Navigate to="/content-analysis/themes" replace />}
                />
              </Route>

              <Route path="metrics" element={<MetricsPage />} />

              <Route path="recommendations" element={<RecommendationsPage />} />

              {FEATURE_FLAGS.ADMIN_SETTINGS.ROOT && (
                <Route path="admin-settings" element={<AdminSettingsPage />}>
                  {FEATURE_FLAGS.ADMIN_SETTINGS.USERS.ROOT ? (
                    <Route
                      index
                      element={<Navigate to="/admin-settings/users" replace />}
                    />
                  ) : (
                    <Route
                      index
                      element={
                        <Navigate
                          to="/admin-settings/global-marker-events"
                          replace
                        />
                      }
                    />
                  )}

                  {FEATURE_FLAGS.ADMIN_SETTINGS.USERS.ROOT && (
                    <Route path="users" element={<SettingsUsers />} />
                  )}

                  {FEATURE_FLAGS.ADMIN_SETTINGS.GLOBAL_MARKERS.ROOT && (
                    <Route
                      path="global-marker-events"
                      element={<SettingsGlobalMarkerEvents />}
                    />
                  )}
                </Route>
              )}

              {FEATURE_FLAGS.PROFILE.ROOT && (
                <Route path="profile" element={<ProfilePage />}>
                  <Route
                    index
                    element={<Navigate to="/profile/overview" replace />}
                  />

                  <Route path="overview" element={<ProfileOverview />} />

                  {FEATURE_FLAGS.PROFILE.MARKER_EVENTS.ROOT && (
                    <Route
                      path="marker-events"
                      element={<ProfileMarkerEvents />}
                    />
                  )}
                </Route>
              )}
            </>
          )}

          {flags[FeatureFlag.CreativeAccess] && (
            <Route path="creative" element={<CreativePage />}>
              <Route
                path="/creative/brand-personality"
                element={
                  <Navigate to="/content-analysis/brand-personality" replace />
                }
              />

              <Route path="channels" element={<CreativeChannelsPage />}>
                <Route path="overview" element={<CreativeChannelsOverview />} />

                <Route
                  path="advertising"
                  element={<CreativeChannelsAdvertising />}
                />

                <Route path="website" element={<CreativeChannelsScreenshots />}>
                  <Route
                    path="all-brands"
                    element={<CreativeChannelsScreenshotsMultiBrand />}
                  />
                  <Route
                    path="brand"
                    element={<CreativeChannelsScreenshotsSingleBrand />}
                  />
                  <Route
                    path="compare"
                    element={<CreativeChannelsScreenshotsCompare />}
                  />
                  {/* Note: do not add an index redirect -- it is handled internally
                    by the Screenshots parent component, to avoid nested outlet
                    components shenanigans
                  */}
                </Route>

                <Route
                  index
                  element={
                    <Navigate
                      to={`/creative/channels/overview${location.search}`}
                    />
                  }
                />
              </Route>
              <Route index element={<Navigate to="/creative/channels" />} />
            </Route>
          )}

          {flags[FeatureFlag.ViewDashboards] && (
            <Route path="/dashboards" element={<DashboardsManagement />} />
          )}

          {flags[FeatureFlag.ViewDashboards] && (
            <Route path="/dashboards/:id" element={<DashboardOverview />} />
          )}

          {/* Handle cases where a use has no access to any app */}
          {!flags[FeatureFlag.BNAccess] &&
            !flags[FeatureFlag.CreativeAccess] && (
              <Route
                path="/*"
                element={
                  <div style={{ paddingLeft: '40px' }}>
                    <p>Looks like there is an issue with your access</p>
                    <a href="mailto:support@blueocean.ai?subject=Brand Navigator Access Help">
                      Contact Us
                    </a>
                  </div>
                }
              />
            )}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
