import styles from './TopBanner.module.scss';

const TopBanner = () => {

  return (
    <div className={styles.CEMXSalute}>
        <div>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.9699 4C17.1987 4 18.3372 4.61879 19.0008 5.63216L19.1271 5.84003L28.8278 22.5825C29.4936 23.6968 29.5023 25.0808 28.8506 26.2034C28.2396 27.2558 27.1372 27.9251 25.9261 27.9942L25.6826 28H6.25716C4.96253 27.9911 3.77146 27.2959 3.13449 26.1774C2.53999 25.1334 2.5129 23.8693 3.04878 22.8069L3.17223 22.5825L12.8126 5.84003C13.4511 4.70405 14.6592 4 15.9699 4Z" fill="#FF6122"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M16 24C15.2637 24 14.6667 23.403 14.6667 22.6667C14.6667 21.9303 15.2637 21.3333 16 21.3333C16.7364 21.3333 17.3334 21.9303 17.3334 22.6667C17.3334 23.403 16.7364 24 16 24ZM16 20C15.2636 20 14.6667 19.403 14.6667 18.6667V12C14.6667 11.2636 15.2636 10.6667 16 10.6667C16.7364 10.6667 17.3334 11.2636 17.3334 12V18.6667C17.3334 19.403 16.7364 20 16 20Z" fill="white"/>
            </svg>
            <p><b>Track is here!</b> All customer data is now live in our powerful new platform, Track. Brand Navigator will be deprecated on <b>April 30, 2025</b>. Contact your CSM today to schedule onboarding and make the switch!</p>
            {/* <a href="https://app.blueocean.ai">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="16" fill="#20434A"/>
                    <rect width="32" height="32" rx="16" stroke="#E6E6E6"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M18.8914 11.3914C19.1355 11.1473 19.5312 11.1473 19.7753 11.3914L23.9419 15.5581C24.186 15.8021 24.186 16.1979 23.9419 16.4419L19.7753 20.6086C19.5312 20.8527 19.1355 20.8527 18.8914 20.6086C18.6473 20.3645 18.6473 19.9688 18.8914 19.7247L21.9911 16.625H8.5C8.15482 16.625 7.875 16.3452 7.875 16C7.875 15.6548 8.15482 15.375 8.5 15.375H21.9911L18.8914 12.2753C18.6473 12.0312 18.6473 11.6355 18.8914 11.3914Z" fill="white"/>
                </svg>
            </a> */}
        </div>
    </div>
  );
};

export default TopBanner;
