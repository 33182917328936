import { Button } from '@blueoceanai/react-component-library';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './ShutdownModal.module.scss';
import { BUTTON_VARIANTS } from '../../../constants/props';
import { CloseIcon , AlertTriangleIcon } from '../../../assets/icons';



const modalInfo = {
    title: '',
    message: '',
    helper: '',
    confirmType: '',
};

export interface ShutdownModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
}

const ShutdownModal = ({
  isModalOpen,
  closeModal
}: ShutdownModalProps) => {
  return (
    <Dialog
      onClose={closeModal}
      className={classNames(styles.ModalBase)}
      classes={{
        paper: styles.ModalPaper,
      }}
      open={isModalOpen}
      {...modalInfo}
    >
      <div className={styles.Header} onClick={closeModal}>
        <h1 className={styles.Title}>Update: Brand Navigator will be deprecated on April 30, 2025</h1>
          <CloseIcon className={styles.Icon} onClick={closeModal} />
      </div>
      <DialogContent className={styles.ShutdownContent}>
        <div className={styles.ColLeft}>
          <AlertTriangleIcon className={styles.Icon} />
        </div>
      
        <div className={styles.ColRight}>
        <b>Track is here!</b> All customer data is now live in our powerful new platform, Track. Brand Navigator will be deprecated on <b>April 30, 2025</b>. Contact your CSM today to schedule onboarding and make the switch!
        </div>
      </DialogContent>

      <footer className={styles.Footer}>
        <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            onClick={closeModal}
          >
            Ok
          </Button>
      </footer>
    </Dialog>
  );
};

export default ShutdownModal;
